// Libs
import PropTypes from 'prop-types';
// Components
import {Close} from '@material-ui/icons';
import {Column} from 'components/Flex';
import {CloseButton, ControlHeader, InfosPanelDiv, StyledRow} from './styled';
import './InfosPanel.css';
// Hooks
import {useControlPanel} from 'Context/ClientControlPanelContext';
import fontStyles from "styles/fonts-jss";


const InfosPanel = (props) => {
  const { showInfosPanel, toggleInfosPanel} = useControlPanel();
  const fonts = fontStyles();

  return !showInfosPanel ? undefined : (
    <InfosPanelDiv className='infos-panel-div'>
      <ControlHeader>
        <div style={{width: "85%", justifyContent: "center", display: "flex"}}>
          <span>LEGENDA</span>
        </div>
        <CloseButton onClick={toggleInfosPanel}><Close /></CloseButton>
      </ControlHeader>
      <Column w='90%' gap='6px' margin='0 0 8px 0'>
          <span className='infos-panel-content-topic'>
              {`DESCARGAS`} 
          </span>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#A8050E"}}></div>
            <span className='infos-panel-content-item'>
              {"10 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#ED5A35"}}></div>
            <span className='infos-panel-content-item'>
              {"20 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#18A91C"}}></div>
            <span className='infos-panel-content-item'>
              {"30 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#00FFFF"}}></div>
            <span className='infos-panel-content-item'>
              {"40 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#0000FF"}}></div>
            <span className='infos-panel-content-item'>
              {"50 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#AAAAAA"}}></div>
            <span className='infos-panel-content-item'>
              {"60 minutos"} 
            </span>
          </StyledRow>
          <span className='infos-panel-content-topic'>
              {`ALVOS`} 
          </span>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#B067E9"}}></div>
            <span className='infos-panel-content-item'>
              {"Buffer de 4 km em alerta"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#FB1C29"}}></div>
            <span className='infos-panel-content-item'>
              {"Buffer de 10 km"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#FDA731"}}></div>
            <span className='infos-panel-content-item'>
              {"Buffer de 20 km"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <div className='square' style={{backgroundColor: "#118014"}}></div>
            <span className='infos-panel-content-item'>
              {"Buffer de 30 km"} 
            </span>
          </StyledRow>
      </Column>
    </InfosPanelDiv>
  )
}

InfosPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};
  
export default InfosPanel;
